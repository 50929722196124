<template>
  <div class="request_client box_content" v-loading="loading">
    <div class="box_header">
      <h2>Клиент</h2>
    </div>
    <div class="box_scroll" v-if="client">
      <div class="client_form form">
        <div class="form_element">
          <el-input
              size="mini"
              placeholder="Имя"
              prefix-icon="el-icon-user"
              v-model="client.name"
              @change="updateClient"
          ></el-input>
        </div>
        <div class="form_element">
          <el-input
              type="textarea"
              v-model="client.comment"
              placeholder="Заметки"
              size="mini"
              :autosize="{ minRows: 2 }"
              @change="updateClient"
          ></el-input>
        </div>

        <div class="contacts_form form">
          <template v-if="client.phones.length">
            <div class="row" v-for="(phone, idx) in client.phones">
              <div class="col col_type">
                <span class="type">
                  <i class="el-icon-mobile-phone icon"></i> Телефон
                </span>
              </div>
              <div class="col col_value">
                <el-button
                    size="mini"
                    v-if="client.phones.length == idx+1"
                    @click="showNewPhone = true"
                    icon="el-icon-plus"
                    plain
                ></el-button>
                <a :href="'tel:+7'+phone.formatted">{{ phone.value }}</a>
                <el-button
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteContact(phone.id)"
                    plain
                    class="delete-button"
                ></el-button>
              </div>
            </div>
          </template>
          <div class="row new_phone" v-if="showNewPhoneDefault || showNewPhone">
            <div class="col col_type">
              <span class="type">
                <i class="el-icon-mobile-phone icon"></i> Телефон
              </span>
            </div>
            <div class="col col_value">
              <el-input
                  size="mini"
                  placeholder="+7 (___) ___-____"
                  v-model="newPhoneValue"
                  v-facade="'+7 (###) ###-####'"
                  @keyup.native.enter="addClientPhone"
                  @keyup.native.esc="showNewPhone = false"
              >
                <el-button slot="append" icon="el-icon-check" type="primary" @click="addClientPhone"></el-button>
              </el-input>
            </div>
          </div>

          <template v-if="client.emails.length">
            <div class="row" v-for="(email, idx) in client.emails">
              <div class="col col_type">
                <span class="type">
                  <i class="el-icon-message icon"></i> Email
                </span>
              </div>
              <div class="col col_value">
                <el-button
                    size="mini"
                    v-if="client.emails.length == idx+1"
                    @click="showNewEmail = true"
                    icon="el-icon-plus"
                    plain
                ></el-button>
                <a :href="'mailto:'+email.value">{{ email.value }}</a>
                <el-button
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteContact(email.id)"
                    plain
                    class="delete-button"
                ></el-button>
              </div>
            </div>
          </template>
          <div class="row new_email" v-if="showNewEmailDefault || showNewEmail">
            <div class="col col_type">
              <span class="type">
                <i class="el-icon-message icon"></i> Email
              </span>
            </div>
            <div class="col col_value">
              <el-input
                  size="mini"
                  placeholder="name@domain.com"
                  v-model="newEmailValue"
                  @keyup.native.enter="addClientEmail"
                  @keyup.native.esc="showNewEmail = false"
              >
                <el-button slot="append" icon="el-icon-check" type="primary" @click="addClientEmail"></el-button>
              </el-input>
            </div>
          </div>

          <template v-if="client.addresses.length">
            <div class="row address" v-for="(address, idx) in client.addresses">
              <div class="col col_type">
                <span class="type">
                  <i class="el-icon-location icon"></i> Адрес
                </span>
              </div>
              <div class="col col_value">
                <el-button
                    size="mini"
                    v-if="client.addresses.length == idx+1"
                    @click="showNewAddress = true"
                    icon="el-icon-plus"
                    plain
                ></el-button>
                <div class="align_wrapper">
                  <span class="address">{{ address.value }}</span>
                </div>
                <el-button
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteAddress(address.address_id)"
                    plain
                    class="delete-button"
                ></el-button>
              </div>
            </div>
          </template>
          <div class="row new_address" v-if="showNewAddressDefault || showNewAddress">
            <div class="col col_type">
              <span class="type">
                <i class="el-icon-location icon"></i> Адрес
              </span>
            </div>
            <div class="col col_value">
              <el-autocomplete
                  popper-class="ac_input_address"
                  :fetch-suggestions="addressSearch"
                  :clearable="true"
                  :debounce="800 + 0"
                  :trigger-on-focus="false"
                  size="mini"
                  placeholder="Начните воодить адрес"
                  v-model="newAddressValue"
                  @select="handleSelectAddress"
                  @keyup.native.esc="showNewAddress = false"
              >
                <template slot-scope="{ item }">
                  <div class="value">{{ item.value }}</div>
                </template>
                <el-button slot="append" icon="el-icon-check" type="primary" @click="addClientAddress"></el-button>
              </el-autocomplete>
            </div>
          </div>
        </div>
      </div>
      <div class="counterparties">
        <div class="block_head">
          <h3>Организации клиента</h3>
          <el-button size="mini" icon="el-icon-plus" plain @click="showNewCounterparty = true"></el-button>
        </div>
        <div class="list" v-if="client.counterparties.length">
          <div class="counterparty" v-for="counterparty in client.counterparties">
            <i class="el-icon-delete" @click="deleteCounterparty(counterparty.id)"></i>
            <div class="name">{{ counterparty.name_short_with_opf }}</div>
            <div class="data">
              ИНН: {{ counterparty.inn }}
              <template v-if="counterparty.kpp">
                / КПП: {{ counterparty.kpp }}
              </template>
              <br>
              {{ counterparty.management_post }}: {{ counterparty.management_name }}
            </div>
          </div>
        </div>
        <div class="empty" v-else>
          <p>Нет связанных организаций</p>
        </div>
        <div class="counterparty_search" v-if="showNewCounterparty">
          <div class="form_element form">
            <label>Поиск организации</label>
            <el-autocomplete
                popper-class="ac_input_party"
                v-model="newCounterpartyObject.name_short_with_opf"
                :fetch-suggestions="partySearch"
                placeholder="Введите инн или наименование для поиска"
                size="mini"
                :clearable="true"
                :debounce="800 + 0"
                :trigger-on-focus="false"
                @select="handleSelectParty"
                @keyup.native.esc="showNewCounterparty = false"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.value }}</div>
                <div class="requisites">ИНН: {{ item.data.inn }}
                  <span v-if="item.data.kpp"> / КПП {{ item.data.kpp }}</span>
                  <span v-if="item.data.management">
                  <br>{{ item.data.management.post }}: {{ item.data.management.name }}
                </span>
                  <span v-if="item.data.address">
                  <br>Адрес: {{ item.data.address.value }}
                </span>
                </div>
              </template>
            </el-autocomplete>
          </div>
        </div>
      </div>
      <div class="many_requests" v-if="client.orders">
        <div class="block_head">
          <h3>Другие заказы</h3>
        </div>
        <div class="request_row" v-for="o in client.orders">
          <div class="request" @click="openOrder(o.id)">
            <span class="status_color_line" :style="{background: o.status.bg}"></span>
            <h5 class="title">Заказ №{{ o.number }}</h5>
            <div class="time">
              <i class="el-icon-time"></i> {{ moment(o.created_at).format('DD.MM.YYYY в HH:mm') }}
            </div>
            <div class="comment">{{ o.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as clientActions} from "@/store/modules/clients";

import autocompleteApi from "@/api/autocomplete";

import price from "@/filters/price";

export default {
  name: "OrderClient",
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  filters: {
    price
  },
  data() {
    return {
      newPhoneValue: '',
      showNewPhone: false,
      newEmailValue: '',
      showNewEmail: false,
      newAddressValue: '',
      newAddressObject: null,
      showNewAddress: false,
      showNewCounterparty: false,
      newCounterpartyObject: {
        name_short_with_opf: '',
      },
    }
  },
  computed: {
    ...mapState({
      client: state => state.clients.current,
      loading: state => state.clients.currentLoading,
      submitting: state => state.clients.currentSubmitting,
    }),
    showNewPhoneDefault() {
      if (this.client.phones.length) return false
      else return true
    },
    showNewEmailDefault() {
      if (this.client.emails.length) return false
      else return true
    },
    showNewAddressDefault() {
      if (this.client.addresses.length) return false
      else return true
    }
  },
  methods: {
    loadClient() {
      this.$store.dispatch(clientActions.getByOrderId, this.orderId)
    },
    updateClient() {
      this.$store.dispatch(clientActions.save, this.client)
    },
    addClientPhone() {
      const data = {
        value: this.newPhoneValue,
        client_id: this.client.id,
        type: 'phone'
      }
      this.$store.dispatch(clientActions.addContact, data).then(() => {
        this.newPhoneValue = '';
        this.showNewPhone = false;
        this.loadClient()
        this.$emit('updated')
      })
    },
    addClientEmail() {
      const data = {
        value: this.newEmailValue,
        client_id: this.client.id,
        type: 'email'
      }
      this.$store.dispatch(clientActions.addContact, data).then(() => {
        this.newEmailValue = '';
        this.showNewEmail = false;
        this.loadClient()
        this.$emit('updated')
      })
    },
    addressSearch(queryString, cb) {
      if (queryString.length > 4) {
        autocompleteApi.searchAddress({query: queryString}).then((result) => {
          if (result.data.length) {
            cb(result.data);
          } else {
            cb([]);
          }
        });
      }
    },
    handleSelectAddress(item) {
      this.addAddressValue = item.value
      this.addAddressObject = item
    },
    addClientAddress() {
      this.$store.dispatch(clientActions.addAddress, {
        client_id: this.client.id,
        address: this.newAddressObject,
        address_str: this.newAddressValue
      }).then(() => {
        this.newAddressObject = null
        this.newAddressValue = ''
        this.showNewAddress = false;
        this.loadClient()
      })
    },
    deleteContact(id) {
      this.$store.dispatch(clientActions.deleteContact, {
        client_id: this.client.id, contact_id: id
      }).then(() => {
        this.loadClient()
        this.$emit('updated')
      })
    },
    deleteAddress(id) {
      this.$store.dispatch(clientActions.deleteAddress, {
        client_id: this.client.id, address_id: id
      }).then(() => {
        this.loadClient()
      })
    },
    addCounterparty() {
      this.$store.dispatch(clientActions.addCounterparty, {
        client_id: this.client.id,
        counterparty: this.newCounterpartyObject
      }).then(() => {
        this.newCounterpartyObject = {
          name_short_with_opf: '',
        }
        this.showNewCounterparty = false
        this.loadClient()
        this.$emit('updated')
      })
    },
    deleteCounterparty(id) {
      this.$store.dispatch(clientActions.deleteCounterparty, {
        client_id: this.client.id, counterparty_id: id
      }).then(() => {
        this.loadClient()
        this.$emit('updated')
      })
    },
    partySearch(queryString, cb) {
      if (queryString.length > 4) {
        autocompleteApi.searchParty({query: queryString}).then((result) => {
          if (result.data.length) {
            cb(result.data);
          } else {
            cb([]);
          }
        });
      }
    },
    handleSelectParty(item) {
      this.newCounterpartyObject.dadata = item
      this.newCounterpartyObject.name_short_with_opf = item.data.name.short_with_opf
      this.addCounterparty()
    },
    clear() {
      this.$store.dispatch(clientActions.clear)
    },
    openOrder(orderId) {
      this.$router.push({path: "/orders/" + orderId});
    },
  },
  mounted() {
    this.loadClient()
  },
  beforeDestroy() {
    this.clear()
  },
  watch: {
    orderId() {
      this.loadClient()
    }
  }
}
</script>
