<template>
  <div class="request_files component-box">
    <div class="component-box__header">
      <h2>Файлы ({{ files.length }})</h2>
    </div>
    <div class="component-box__content">
      <div class="list" v-if="files.length">
        <div class="file-item" v-for="file in files">
          <el-button
              size="mini"
              circle
              type="danger"
              icon="el-icon-delete"
              class="delete_btn"
              @click="remove(file.id)"
          ></el-button>
          <div class="element">
            <el-image v-if="file.ext == 'jpeg' || file.ext == 'jpg' || file.ext == 'png'"
                      style="width: 200px;height: 200px;"
                      class="image"
                      fit="cover"
                      :src="'https://online.lbsteklo.ru/files/'+file.file"
                      :preview-src-list="['https://online.lbsteklo.ru/files/'+file.file]"
            ></el-image>
            <div v-else class="icon" @click="openFile(file.file)">
              <v-icon scale="5" v-if="file.ext == 'pdf'" name="file-pdf"></v-icon>
              <v-icon scale="5" v-else-if="file.ext == 'doc' || file.ext == 'docx'" name="file-word"></v-icon>
              <v-icon scale="5" v-else-if="file.ext == 'xls' || file.ext == 'xlsx' || file.ext == 'XLS'"
                      name="file-excel"></v-icon>
              <v-icon scale="5" v-else-if="file.ext == 'dwg'" name="unity"></v-icon>
              <v-icon scale="5" v-else-if="file.ext == 'zip'" name="file-archive"></v-icon>
              <v-icon scale="5" v-else name="file"></v-icon>
            </div>
            <span class="title">
              <el-input v-model="file.title" size="mini" @change="saveFile(file)"></el-input>
            </span>
          </div>
        </div>
        <div class="file-item">
          <el-upload
              class="upload-file"
              drag
              action="https://online.lbsteklo.ru/v21/files/upload/"
              :show-file-list="false"
              :data="{oid: orderId}"
              :headers="{Authorization: getLocalToken()}"
              :on-success="successFileUpload"
              :on-progress="progressFileUpload"
              :before-upload="beforeFileUpload"
              multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Перетащите сюда файлы или <br><em>нажмите для выбора</em></div>
            <div class="el-upload__tip" slot="tip">jpg/png изображения или любые другие файлы. <br>Можно загружать сразу
              несколько файлов.
            </div>
          </el-upload>
        </div>
      </div>
      <div class="list" v-else>
        <div class="file-item">
          <el-upload
              class="upload-file"
              drag
              action="https://online.lbsteklo.ru/v21/files/upload/"
              :show-file-list="false"
              :data="{oid: orderId}"
              :headers="{Authorization: getLocalToken()}"
              :on-success="successFileUpload"
              :on-progress="progressFileUpload"
              :before-upload="beforeFileUpload"
              multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Перетащите сюда файлы или <br><em>нажмите для выбора</em></div>
            <div class="el-upload__tip" slot="tip">jpg/png изображения или любые другие файлы. <br>Можно загружать сразу
              несколько файлов.
            </div>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as fileActions} from "@/store/modules/files";


import {getItem} from "@/helpers/persistanceStorage";

export default {
  name: "OrderFiles",
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      expand: false
    }
  },
  computed: {
    ...mapState({
      files: state => state.files.list,
      loading: state => state.files.loading,
      submitting: state => state.files.submitting
    }),
  },
  methods: {
    loadFiles() {
      this.$store.dispatch(fileActions.getOrderFiles, this.orderId)
    },
    openFile(filename) {
      window.open('https://online.lbsteklo.ru/files/' + filename, '_blank');
    },
    getLocalToken() {
      const token = getItem('accessToken')
      return token ? `Token ${token}` : ''
    },
    progressFileUpload() {
      //
    },
    beforeFileUpload() {
      //
    },
    successFileUpload() {
      this.loadFiles()
    },
    saveFile(file){
      this.$store.dispatch(fileActions.saveFile, file)
    },
    remove(id){
      this.$store.dispatch(fileActions.removeFile, id).then(() => {
        this.loadFiles()
      })
    },
    clear() {
      this.$store.dispatch(fileActions.clear)
    }
  },
  mounted() {
    this.loadFiles()
  },
  beforeDestroy() {
    this.clear()
  },
  watch: {
    orderId() {
      this.loadFiles()
    }
  }
}
</script>
