<template>
  <div class="request-timeline box_content">
    <div class="box_header">
      <h2>История</h2>
    </div>
    <div class="scroll_box" v-loading="loading">
      <div class="timeline request-timeline" v-if="elements">
        <div class="timeline_element" v-for="(element,idx) in elements" :key="idx">
          <div class="call" v-if="element.event_type === 'call'">
            <div class="call_icon">
              <v-icon name="phone-slash"
                      v-if="element.state == 'Missed' || element.state == 'Rejected'"
                      style="color: red"
              ></v-icon>
              <v-icon name="phone-alt"
                      v-else
                      style="color: #ccc"
              ></v-icon>
            </div>
            <div class="call_info">
              <span v-if="element.personality === 'Originator'">Исходящий законок на номер</span>
              <span v-else>
                <template v-if="element.state === 'Missed'">Пропущенный звонок с номера</template>
                <template v-else-if="element.state === 'Rejected'">Сброшенный звонок с номера</template>
                <template v-else>Входящий звонок с номера</template>
              </span> {{ element.address }}
              <audio controls preload="none" class="player" v-if="element.record_file">
                <source :src="'https://online.lbsteklo.ru/audio/' + element.record_file" type="audio/mp3"/>
              </audio>
              <div class="time">
                <i class="el-icon-time"></i> {{ moment(element.start_time).format('DD.MM.YYYY в HH:mm') }}
                <span class="duration" v-if="element.answer_time">
            || Длительность {{ duration(element) }}
          </span>
              </div>
            </div>
          </div>
          <div class="wa_message" v-else-if="element.event_type === 'wa_message'"
               :class="{from_me: Number(element.from_me)}">
            <div class="message">

              <template v-if="element.type === 'chat'">
                {{ element.body }}
              </template>
              <template v-else-if="element.type === 'image'">
                <el-image
                    :src="'https://online.lbsteklo.ru/wa/images/'+element.body"
                    style="width: 250px; height: 250px"
                    fit="cover"
                    scroll-container="list__lazy"
                    :preview-src-list="['https://online.lbsteklo.ru/wa/images/'+element.body]"
                    :z-index="Number(6000)"
                ></el-image>
              </template>
              <template v-else-if="element.type === 'document'">
                <el-link
                    type="primary"
                    :underline="false"
                    :href="element.body"
                    target="_blank"
                >Скачать файл
                </el-link>
              </template>
              <template v-else-if="element.type === 'ptt' || element.type === 'audio'">
                <audio controls preload="none" class="player">
                  <source :src="element.body" type="audio/ogg; codecs=vorbis"/>
                </audio>
              </template>
              <template v-else-if="element.type === 'video'">
                <video :src="element.body" style="max-width: 100%" preload="metadata" controls="controls"></video>
              </template>
              <template v-else-if="element.type === 'call_log'">
                Пропущеный звонок
              </template>
              <template v-else-if="element.type === 'vcard' || element.type === 'multi_vcard'">
                {{ element.body }}
              </template>
              <template v-else>
                {{ element.body }}
              </template>
            </div>
            <div class="time">
              <i class="el-icon-time"></i> {{ moment(element.time).format('DD.MM.YYYY в HH:mm') }}
            </div>
          </div>
        </div>
      </div>
      <div class="timeline" v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as timelineActions} from "@/store/modules/timeline";

export default {
  name: "OrderTimeline",
  props: {
    orderId: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapState({
      elements: state => state.timeline.elements,
      loading: state => state.timeline.loading
    })
  },
  methods: {
    loadTimeline() {
      this.$store.dispatch(timelineActions.getByOrderId, this.orderId)
    },
    duration(element) {
      let duration = '';
      var a = this.moment(element.release_time);
      var b = this.moment(element.answer_time);
      var c = a.diff(b)

      let minutes = this.moment.duration(c).minutes()
      let seconds = this.moment.duration(c).seconds()
      if (minutes > 0) {
        duration = minutes + ' мин '
      }
      duration += seconds + ' сек'

      return duration
    },
    clear() {
      this.$store.dispatch(timelineActions.clear)
    }
  },
  updated() {
    this.$nextTick(function () {
      const container = this.$el.querySelector('.scroll_box')
      container.scrollTop = container.scrollHeight;
    });
  },
  mounted() {
    this.loadTimeline()
  },
  beforeDestroy() {
    this.clear()
  }
}
</script>
