<template>
  <div class="order page" id="requestPage">
    <div class="client_area">
      <div class="box">
        <order-client :order-id="id" @updated="clientUpdated"></order-client>
      </div>
    </div>
    <div class="request_area">
      <div class="box">
        <div class="scroll_box">
          <div class="order_page_box" v-loading="loading">
            <div class="item" v-if="order">
              <div class="preview">
                <div class="title">
                  Заказ №
                  <el-input class="doc_num_input" size="mini" v-model="order.number"
                            @change="changeOrder(order)"></el-input>
                </div>
                <div class="time">
                  <i class="el-icon-time"></i> Создан {{ moment(order.created_at).format('DD.MM.YYYY HH:mm') }}<span
                    v-if="order.updated_at" class="last_update">,
                изменен {{ moment(order.updated_at).calendar() }}</span>
                </div>
                <div class="money">
                  <span class="price">Сумма: <strong>{{ order.sum | price }}</strong> р</span>
                  <span class="price">Со скидкой: <strong>{{ order.sum_wd  | price }}</strong> р</span>
                  <span class="price">Скидка: <strong>{{ (order.sum - order.sum_wd)  | price
                    }}</strong> р</span>
                </div>
              </div>
              <div class="data">
                <div class="actions">

                  <el-dropdown trigger="click" @command="setDiscount" class="discount_btn"
                               v-if="order.positions.length">
                    <el-button type="primary" plain size="mini" icon="el-icon-discount">Скидка по документу</el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="{id: order.id, discount: 0 }">Без скидки</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 5 }">5%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 10 }">10%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 15 }">15%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 20 }">20%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 25 }">25%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 30 }">30%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 35 }">35%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 40 }">40%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 45 }">45%</el-dropdown-item>
                      <el-dropdown-item :command="{id: order.id, discount: 50 }">50%</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>

                  <el-select
                      v-model="order.status_id"
                      placeholder="Статус заказа"
                      @change="changeOrder(order)"
                      class="request_status_selector"
                      size="mini"
                      :style="{color: order.status.color, background: order.status.bg, borderRadius: '5px'}"
                  >
                    <el-option
                        v-for="status in statuses"
                        :key="status.id"
                        :label="status.title"
                        :value="status.id"
                        class="request_status_selector_item"
                        v-if="statuses">
                      <span class="status_in_select" :style="{
                          backgroundColor: status.bg,
                          color: status.color
                        }"> {{ status.title }}</span>
                    </el-option>
                  </el-select>

                  <el-button
                      type="info"
                      plain
                      size="mini"
                      icon="el-icon-copy-document"
                      @click="copy(order)">Копировать
                  </el-button>

                  <el-dropdown trigger="click" @command="print" class="action_btn" style="margin: 0 0 0 10px">
                    <el-button type="primary" plain size="mini" icon="el-icon-printer">PDF</el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                          v-for="t in documentTemplates"
                          :key="t.id"
                          :command="{template: t.id, type: 'order', id: order.id , pdf: 'pdf'}"
                          v-if="t.for_orders">
                        {{ t.title }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>

                  <el-dropdown trigger="click" @command="print" class="action_btn" style="margin: 0 10px 0 10px">
                    <el-button type="primary" plain size="mini" icon="el-icon-download">Word</el-button>

                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                          v-for="t in documentTemplates"
                          :key="t.id"
                          :command="{template: t.id, type: 'order', id: order.id , pdf: ''}"
                          v-if="t.for_orders">
                        {{ t.title }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>

                  <el-dropdown v-if="order.act_need" trigger="click" @command="print" class="action_btn"
                               style="margin: 0 10px 0 0">
                    <el-button type="primary" plain size="mini" icon="el-icon-printer">Акт</el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                          v-for="t in documentTemplates"
                          :key="t.id"
                          :command="{template: t.id, type: 'act', id: order.id , pdf: 'pdf'}"
                          v-if="t.for_acts">
                        {{ t.title }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>

                  <el-button
                      type="danger"
                      size="mini"
                      icon="el-icon-delete"
                      @click="deleteOrder(order.id)">Удалить
                  </el-button>
                </div>
                <div style="padding: 10px;">
                  <el-alert
                      v-if="order.act_need"
                      type="warning"
                      title="В заказе присутствуют позиции для которых нужно подписать акт, не забудьте его распечатать."
                  ></el-alert>
                </div>

                <div class="params">
                  <div class="org">
                    <label>Организация</label>
                    <el-select
                        v-model="order.org_id"
                        placeholder="Выберите организацию"
                        size="mini"
                        @change="changeOrder(order)"
                    >
                      <el-option
                          v-for="org in organizations"
                          :key="org.id"
                          :label="org.title"
                          :value="org.counterparty.id"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="pre_pay">
                    <label>Предоплата, р</label>
                    <el-input-number
                        size="mini"
                        v-model="order.pre_pay"
                        :min="0"
                        :max="Number(order.sum_wd)"
                        :precision="2"
                        @change="changePrePay"
                    ></el-input-number>
                  </div>
                  <div class="post_pay">
                    <label>Остаток, р</label>
                    <el-input-number
                        size="mini"
                        v-model="order.post_pay"
                        :min="0"
                        :max="Number(order.sum_wd)"
                        @change="changePostPay"
                    ></el-input-number>
                  </div>
                  <div class="doc_days">
                    <label>Срок, дней</label>
                    <el-input-number
                        size="mini"
                        v-model="order.doc_days"
                        :min="Number(order.min_delivery_days)"
                        @change="changeOrder(order)"
                    ></el-input-number>
                  </div>
                  <div class="project">
                    <label>Проект</label>

                    <el-radio-group v-model="order.project_id" size="mini" @change="changeOrder(order)">
                      <el-radio-button label="3">Самовывоз</el-radio-button>
                      <el-radio-button label="4">Доставка</el-radio-button>
                      <el-radio-button label="2">Монтаж</el-radio-button>
                    </el-radio-group>
                  </div>

                  <div class="client" v-if="client">
                    <label>Клиент</label>
                    <el-select
                        v-model="order.client_org_id"
                        placeholder="Организация клиента"
                        size="mini"
                        @change="changeOrder(order)"
                    >
                      <el-option label="Как физ лицо" :value="null"></el-option>
                      <el-option
                          v-if="client.counterparties.length"
                          v-for="org in client.counterparties"
                          :key="org.id"
                          :label="org.name_short_with_opf"
                          :value="org.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="positions">
                  <table class="table document_edit">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th></th>
                      <th>Наименование</th>
                      <th></th>
                      <th>Кол-во</th>
                      <th>Цена, р</th>
                      <th>Скидка, %</th>
                      <th>Сумма р</th>
                      <th></th>
                    </tr>
                    </thead>
                    <draggable tag="tbody" v-model="order.positions" draggable=".item"
                               @change="reorder(order.positions)">
                      <tr v-for="(p,idx) in order.positions" class="item">
                        <td>{{ ++idx }}</td>
                        <td class="image">
                          <div class="i" v-if="p.assortment.image">
                            <el-tooltip placement="right" effect="light">
                              <div slot="content">
                                <img :src="'https://online.lbsteklo.ru/images/full/' +p.assortment.image.file"
                                     style="max-width: 500px; display: inline-block; vertical-align: middle;"
                                />
                              </div>
                              <img :src="'https://online.lbsteklo.ru/images/tiny/' +p.assortment.image.file"
                                   style="max-width: 100%; display: inline-block; vertical-align: middle;"
                              />
                            </el-tooltip>
                          </div>
                        </td>
                        <td>
                          <span class="title">{{ p.assortment.title }}</span>
                          <span class="codes">
                        <span class="code" v-if="p.assortment.code">Код: {{ p.assortment.code }}</span>
                        <span class="code" v-if="p.assortment.sku">Артикул: {{ p.assortment.sku }}</span>
                      </span>

                        </td>
                        <td style="width: 40px">
                          <el-dropdown trigger="click" @command="setPositionPrice" class="action_btn">
                            <el-button size="mini" icon="el-icon-more" circle class="btn_more"></el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item :command="{id: p.id, price: p.assortment.retail_price }">
                                Розничная цена: {{ p.assortment.retail_price | price }}р
                              </el-dropdown-item>
                              <el-dropdown-item :command="{id: p.id, price: p.assortment.wholesale_base_price }">
                                Оптовая цена: {{ p.assortment.wholesale_base_price | price }}р
                              </el-dropdown-item>
                              <el-dropdown-item :command="{id: p.id, price: p.assortment.min_price }">
                                Минимальная цена: {{ p.assortment.min_price | price }}р
                              </el-dropdown-item>
                              <el-dropdown-item divided :command="{id: p.id, price: p.assortment.buy_price }">
                                Цена закупки: {{ p.assortment.buy_price | price }}р
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </td>
                        <td style="width: 170px">
                          <el-input-number
                              size="mini"
                              v-model="p.qty"
                              :precision="Number(p.assortment.uom.decimal_places)"
                              :step="1"
                              @change="updatePosition(p)"
                          ></el-input-number>
                          {{ p.assortment.uom.short }}
                        </td>
                        <td style="width: 168px">
                          <el-input-number
                              size="mini"
                              v-model="p.price"
                              :precision="2"
                              :step="1"
                              @change="updatePosition(p)"
                          ></el-input-number>
                          р
                        </td>
                        <td style="width: 120px">
                          <el-input-number
                              size="mini"
                              v-model="p.discount"
                              :precision="0"
                              :step="1"
                              @change="updatePosition(p)"
                              style="width: 90px;"
                          ></el-input-number>
                          %
                        </td>
                        <td class="sum">
                          {{ (p.price * p.qty - ((p.price * p.qty) / 100) * p.discount) | price }} р
                        </td>
                        <td>
                          <el-button size="mini" plain icon="el-icon-delete" class="delete_btn"
                                     @click="deletePosition(p)"></el-button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <el-autocomplete
                              v-model="newPosition"
                              :fetch-suggestions="searchAssortments"
                              size="mini"
                              placeholder="Введите наименование, код или артикул"
                              @select="addSelectedPosition"
                              :debounce="500"
                              :trigger-on-focus="false"
                              class="assortment_autocomplete"
                              popper-class="assortment_autocomplete"
                          >
                            <template slot-scope="{ item }">
                              <div class="row">
                                <div class="image">
                                  <img :src="'https://online.lbsteklo.ru/images/tiny/' + item.images[0].file"
                                       v-if="item.images.length"
                                  />
                                </div>
                                <span class="title">{{ item.title }}</span>
                                <span class="codes">
                              <span class="code" v-if="item.code">Код: {{ item.code }}</span>
                              <span class="code" v-if="item.sku">Артикул: {{ item.sku }}</span>
                            </span>

                              </div>
                            </template>
                          </el-autocomplete>
                        </td>
                        <td>
                          <el-button size="mini">Подбор</el-button>
                        </td>
                        <td colspan="2" style="text-align: right" class="total">
                          Итого:
                        </td>
                        <td colspan="2" class="total">
                          {{ order.sum | price }} р
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7" style="text-align: right;" class="total">
                          Итого со скидкой:
                        </td>
                        <td colspan="2" class="total">
                          {{ order.sum_wd | price }} р
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7" style="text-align: right;" class="total">
                          Скидка:
                        </td>
                        <td colspan="2" class="total">
                          {{ order.sum - order.sum_wd | price }} р
                        </td>
                      </tr>

                    </draggable>
                  </table>
                </div>

                <div class="comment">
                  <el-input
                      type="textarea"
                      v-model="order.comment"
                      placeholder="Комментарий"
                      size="mini"
                      :autosize="{ minRows: 2 }"
                      @change="changeOrder(order)"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
          <order-files :order-id="id" v-if="order"></order-files>
          <order-payments :order-id="id" :order="order" v-if="order"></order-payments>
          <order-assessments :order-id="id"></order-assessments>
          <order-mountings :order-id="id"></order-mountings>
        </div>
      </div>
    </div>
    <div class="history_area">
      <div class="box">
        <order-timeline :order-id="id"></order-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import price from "@/filters/price";
import qty from "@/filters/qty";
import role from "@/filters/role";

import OrderClient from "@/components/order/OrderClient";
import OrderTimeline from "@/components/order/OrderTimeline";
import OrderPayments from "@/components/order/OrderPayments";
import OrderMountings from "@/components/order/OrderMountings";
import OrderAssessments from "@/components/order/OrderAssessments";

import {actionTypes as orderActions} from "@/store/modules/orders";
import {actionTypes as timelineActions} from "@/store/modules/timeline";
import {mapState} from "vuex";

import autocomplete from "@/api/autocomplete";

import draggable from 'vuedraggable'
import OrderFiles from "@/components/order/OrderFiles";

export default {
  name: "Order",
  filters: {
    price, qty
  },
  data() {
    return {
      newPosition: '',
      showPositions: false,
    }
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  },
  components: {
    OrderFiles,
    OrderAssessments,
    OrderMountings,
    OrderPayments,
    OrderTimeline,
    OrderClient,
    draggable
  },
  computed: {
    ...mapState({
      documentTemplates: state => state.document_templates.templates,
      statuses: state => state.statuses_for_orders.list,
      organizations: state => state.organizations.list,
      client: state => state.clients.current,
      order: state => state.orders.current,
      loading: state => state.orders.currentLoading
    }),
    id() {
      return Number(this.$route.params.id)
    }
  },
  methods: {
    searchAssortments(queryString, cb) {
      if (
          queryString !== undefined &&
          queryString !== null &&
          queryString.length > 2
      ) {
        autocomplete
            .searchAssortment({query: queryString})
            .then((result) => {
              if (result.data.length) {
                cb(result.data);
              } else {
                cb([]);
              }
            });
      } else {
        cb([]);
      }
    },
    addSelectedPosition(item) {
      this.$store.dispatch(orderActions.addPosition, {oid: this.order.id, aid: item.id})
          .then(() => {
            this.loadOrder()
          });
    },
    reorder(items) {
      this.$store.dispatch(orderActions.reorder, items)
    },
    setPositionPrice(pp) {
      this.$store.dispatch(orderActions.setPositionPrice, pp).then(() => {
        this.loadOrder()
      })
    },
    updatePosition(p) {
      this.$store.dispatch(orderActions.updatePosition, p).then(() => {
        this.loadOrder()
      })
    },
    deletePosition(p) {
      this.$store.dispatch(orderActions.deletePosition, p).then(() => {
        this.loadOrder()
      })
    },
    changePrePay() {
      this.order.post_pay = Number(this.order.sum_wd - this.order.pre_pay).toFixed(2)
      this.changeOrder(this.order)
    },
    changePostPay() {
      this.order.pre_pay = Number(this.order.sum_wd - this.order.post_pay).toFixed(2)
      this.changeOrder(this.order)
    },
    changeOrder(order) {
      this.$store.dispatch(orderActions.save, order).then(() => {
        this.loadOrder()
      })
    },
    copy(order) {
      this.$store.dispatch(orderActions.copy, order).then(() => {
        this.loadOrder()
      })
    },
    setDiscount(c) {
      this.$store.dispatch(orderActions.setDiscount, c).then(() => {
        this.loadOrder()
      })
    },
    print(c) {
      window.open(
          `https://online.lbsteklo.ru/create/document/${c.type}/${c.id}/${c.template}/${c.pdf}`,
          '_blank'
      );
    },
    clientUpdated() {
      this.loadOrder()
      this.reloadTimeline()
    },
    loadOrder() {
      this.$store.dispatch(orderActions.getCurrent, this.id)
    },
    reloadTimeline() {
      this.$store.dispatch(timelineActions.getByOrderId, this.id)
    },
    clear() {
      this.$store.dispatch(orderActions.clearCurrent)
    }
  },
  mounted() {
    this.loadOrder()
  },
  beforeDestroy() {
    this.clear()
  },
  watch: {
    $route(to, from) {
      this.loadOrder()
    }
  },
}
</script>
