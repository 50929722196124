<template>
  <div class="request_assessments component-box">
    <div class="component-box__header">
      <h2>Замеры ({{ assessments.length }})</h2>
      <el-button size="mini" plain icon="el-icon-plus" @click="create">Замер</el-button>
    </div>
    <div class="component-box__content">
      <div class="list" v-if="assessments.length">
        <div class="item" v-for="event in assessments" :key="event.id">
          <div class="title">
            <span class="time">
              <i class="el-icon-time"></i> {{ moment(event.start_date).format('DD.MM.YYYY') }}
              c {{ moment(event.start_time, 'HH:mm:ss').format('HH:mm') }}
              до {{ moment(event.end_time, 'HH:mm:ss').format('HH:mm') }}
            </span>
          </div>
          <div class="address">Адрес: {{ event.address.value }}</div>
          <div class="installer">
            <span v-if="event.brigade">Бригада: {{ event.brigade.title }}</span>
            <span v-if="event.fitter">Монтажник: {{ event.fitter.fio_full }}</span>
          </div>
          <div class="comment">
            {{ event.manager_comment }}
          </div>
          <div class="comment">
            {{ event.fitter_comment }}
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>

    <el-dialog
        title="Запись на замер"
        :visible.sync="showCreator"
        width="900px"
        @close="closeCreator"
        :destroy-on-close="true">
      <div class="createAssessment form">
        <el-row :gutter="0">
          <el-col :span="8">
            <div class="form_element">
              <label>Кто будет выполнять замер?</label>
              <el-radio-group v-model="newAssessment.f_o_b" size="mini">
                <el-radio-button label="b">Бригада</el-radio-button>
                <el-radio-button label="f">Монтажник</el-radio-button>
              </el-radio-group>
            </div>

            <div class="form_element" v-if="newAssessment.f_o_b == 'f'">
              <label>Выберите монтажника</label>
              <el-select v-model="newAssessment.fitter_id" size="mini" @change="getSchedule">
                <el-option v-for="f in fitters" :label="f.fio_full" :value="f.id"></el-option>
              </el-select>
            </div>

            <div class="form_element" v-if="newAssessment.f_o_b == 'b'">
              <label>Выберите бригаду</label>
              <el-select v-model="newAssessment.brigade_id" size="mini" @change="getSchedule">
                <el-option v-for="b in brigades" :label="b.title" :value="b.id"></el-option>
              </el-select>
            </div>

            <div class="form_element">
              <label>Дата замера</label>
              <el-date-picker
                  v-model="newAssessment.start_date"
                  type="date"
                  placeholder="Выберите день" size="mini"
                  format="dd.MM.yyyy"
                  @change="getSchedule"
              >
              </el-date-picker>
            </div>
            <div class="form_element">
              <label>Время замера</label>
              <el-row :gutter="10" class="time_selector">
                <el-col :span="12">
                  <el-time-select
                      v-model="newAssessment.start_time"
                      :picker-options="{
                    start: '07:00',
                    step: '01:00',
                    end: '22:00',
                  }"
                      size="mini"
                      placeholder="От">
                  </el-time-select>

                </el-col>
                <el-col :span="12">
                  <el-time-select
                      v-model="newAssessment.end_time"
                      :picker-options="{
                    start: '07:00',
                    step: '01:00',
                    end: '23:00',
                    minTime: newAssessment.start_time
                  }"
                      size="mini"
                      placeholder="До">
                  </el-time-select>

                </el-col>
              </el-row>
            </div>

            <div class="form_element">
              <label>Комментарий</label>
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  placeholder="Информация для замерщика"
                  v-model="newAssessment.manager_comment">
              </el-input>
            </div>
            <div class="form_element" v-if="client">
              <label>Адрес замера</label>
              <el-select v-if="client.addresses.length" v-model="newAssessment.address_id">
                <el-option v-for="a in client.addresses" :key="a.address_id" :value="a.address_id"
                           :label="a.value"></el-option>
              </el-select>
              <div v-else>
                <el-alert type="warning">У клиента не указан ни один адрес</el-alert>
              </div>
            </div>

          </el-col>
          <el-col :span="16">
            <div class="schedule">
              <div class="schedule-content" v-if="schedule.length">
                <div class="schedule-event" v-for="event in schedule" :key="event.id">
                  <div class="icon">
                    <v-icon name="pencil-ruler" v-if="event.type == 'assessment'"></v-icon>
                    <v-icon name="hammer" v-if="event.type == 'mounting'"></v-icon>
                  </div>
                  <div class="title">
                    <span class="time">
                      <i class="el-icon-time"></i> {{ moment(event.start_date).format('DD.MM.YYYY')
                      }} c {{ moment(event.start_time, 'HH:mm:ss').format('HH:mm')
                      }} до {{ moment(event.end_time, 'HH:mm:ss').format('HH:mm') }}
                    </span>
                    <span v-if="event.type == 'assessment'">Замер </span>
                    <span v-if="event.type == 'mounting'">Монтаж </span>
                  </div>
                  <div class="address">Адрес: {{ event.address.value }}</div>
                  <div class="installer">
                    <span v-if="event.brigade">Бригада: {{ event.brigade.title }}</span>
                    <span v-if="event.fitter">Монтажник: {{ event.fitter.fio_full }}</span>
                  </div>
                  <div class="comment">
                    {{ event.manager_comment }}
                  </div>
                </div>
              </div>
              <div class="schedule-empty" v-else>
                <p v-if="filterSelected">Нет записей в расписании</p>
                <p v-else>Выберите бригаду/монтажника и дату, чтобы увидеть расписание.</p>
              </div>
            </div>
          </el-col>

        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCreator">Отменить</el-button>
        <el-button type="primary" @click="createAssessment">Записать</el-button>
      </span>

    </el-dialog>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as assessmentActions} from "@/store/modules/assessments";
import {actionTypes as calendarActions} from "@/store/modules/calendar";

export default {
  name: "OrderAssessments",
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      expand: false,
      showCreator: false,
      newAssessment: {
        type: 'assessment',
        f_o_b: 'f',
        brigade_id: null,
        fitter_id: null,
        order_id: null,
        address_id: null,
        manager_comment: '',

        start_date: new Date(),
        start_time: null,

        end_date: null,
        end_time: null,
      }
    }
  },
  computed: {
    ...mapState({
      assessments: state => state.assessments.orderList,
      schedule: state => state.calendar.filteredList,
      brigades: state => state.brigades.list,
      employees: state => state.employees.list,
      client: state => state.clients.current
    }),
    fitters() {
      return _.filter(this.employees, function (e) {
        if (e.user.role == 'fitter') {
          if (e.first_name == null) e.first_name = '';
          if (e.last_name == null) e.last_name = '';
          if (e.middle_name == null) e.middle_name = '';
          e.full_name = e.last_name + ' ' + e.first_name + ' ' + e.middle_name
          return e
        }
      })
    },
    filterSelected() {
      if (this.newAssessment.f_o_b == 'f') {
        if (this.newAssessment.fitter_id == null || this.newAssessment.start_date == null)
          return false
      } else {
        if (this.newAssessment.brigade_id == null || this.newAssessment.start_date == null)
          return false
      }
      return true
    }
  },
  methods: {
    createAssessment() {
      this.newAssessment.order_id = this.orderId
      this.$store.dispatch(assessmentActions.saveAssessment, this.newAssessment).then(() => {
        this.showCreator = false;
        this.loadAssessments()
      })
    },
    closeCreator() {
      this.showCreator = false
    },
    loadAssessments() {
      this.$store.dispatch(assessmentActions.getOrderAssessments, this.orderId)
    },
    getSchedule() {
      if (this.filterSelected) {
        this.$store.dispatch(calendarActions.getFiltered, {
          f_o_b: this.newAssessment.f_o_b,
          brigade_id: this.newAssessment.brigade_id,
          fitter_id: this.newAssessment.fitter_id,
          start_date: this.newAssessment.start_date,
        })
      }
    },
    create() {
      this.expand = true
      this.showCreator = true
      this.newAssessment = {
        type: 'assessment',
        f_o_b: 'f',
        brigade_id: null,
        fitter_id: null,
        order_id: null,
        address_id: null,
        manager_comment: '',

        start_date: new Date(),
        start_time: null,

        end_date: null,
        end_time: null,
      }

      this.$store.dispatch(calendarActions.clear)
    },
    clear() {
      this.$store.dispatch(assessmentActions.clear)
    }
  },
  mounted() {
    this.loadAssessments()
  },
  beforeDestroy() {
    this.clear()
  },
  watch: {
    orderId() {
      this.loadAssessments()
    }
  }
}
</script>
