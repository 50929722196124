<template>
  <div class="request_payments component-box">
    <div class="component-box__header">
      <h2>Платежи ({{ payments.length }})</h2>
      <el-button size="mini"
                 plain
                 icon="el-icon-plus"
                 @click="create"
                 v-if="(order.sum_wd - orderPayed) > 0"
      >Новый платеж</el-button>
    </div>
    <div class="component-box__content">
      <div class="list" v-if="payments.length">
        <div class="payment item" v-for="payment in payments">
          <div class="payment_box">
            <div class="title">
              Платеж №{{ payment.number }}
            </div>
            <div class="time">
              <i class="el-icon-time"></i> от {{ moment(payment.created_at).format('DD.MM.YYYY HH:mm') }}
            </div>
            <div class="money">
              <span class="price">Сумма: <strong>{{ payment.sum | price }}</strong> р</span>
            </div>
            <p class="comment" v-if="payment.purpose">Назначение: {{ payment.purpose }}</p>
            <p class="comment" v-if="payment.comment">Комментарий: {{ payment.comment }}</p>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>
    <el-dialog
        title="Новый платеж"
        :visible.sync="showCreator"
        width="400px"
        @close="closeCreator"
        :destroy-on-close="true">
      <div class="createPayment">
        <div class="paymentForm">
          <template v-if="newPayment.order">
            <p class="order_info">Заказ №{{ newPayment.order.number }} от
              {{ moment(newPayment.order.created_at).format('DD.MM.YYYY HH:mm') }}</p>
            <span v-if="orderPayed > 0" class="money">
                Уже выплачено {{ orderPayed }}р, осталось {{ newPayment.order.sum_wd - orderPayed | price }}р
            </span>
            <span v-else class="money">
                Сумма к оплате {{ newPayment.order.sum_wd }}р
                <template v-if="newPayment.order.pre_pay">
                  / Предоплата {{ newPayment.order.pre_pay | price }}р
                </template>
            </span>
            <div class="payment_types">
              <el-radio-group v-model="newPayment.payment_type_id" size="mini">
                <el-radio-button :label="pt.id" v-for="pt in paymentTypes">{{ pt.title }}</el-radio-button>
              </el-radio-group>
            </div>
            <div class="sum">
              <label>Сумма платежа</label>
              <el-input-number v-model="newPayment.sum"
                               size="mini"
                               :precision="2"
              ></el-input-number>
            </div>
            <div class="form_element">
              <el-input
                  type="textarea"
                  v-model="newPayment.purpose"
                  placeholder="Назначение платежа"
                  size="mini"
                  :autosize="{ minRows: 2 }"
              ></el-input>
            </div>
            <div class="form_element">
              <el-input
                  type="textarea"
                  v-model="newPayment.comment"
                  placeholder="Комментарий"
                  size="mini"
                  :autosize="{ minRows: 2 }"
              ></el-input>
            </div>

          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="closeCreator">Отменить</el-button>
    <el-button type="primary" @click="createPayment">Принять</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as paymentActions} from "@/store/modules/payments";
import {actionTypes as orderActions} from "@/store/modules/orders";

import price from "@/filters/price";

export default {
  name: "PrderPayments",
  props: {
    orderId: {
      type: Number,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  filters: {
    price
  },
  data() {
    return {
      showCreator: false,
      newPayment: {
        order: null,
        payment_type_id: null,
        sum: 0,
        purpose: '',
        comment: '',
      },
    }
  },
  computed: {
    ...mapState({
      payments: state => state.payments.orderList,
      paymentTypes: state => state.paymentTypes.list
    }),
    orderPayed() {
      let payed = 0
      this.order.payments.forEach((p) => {
        payed = payed + Number(p.sum)
      })
      return payed.toFixed(2)
    }
  },


  methods: {
    createPayment() {
      this.$store.dispatch(paymentActions.savePayment, this.newPayment).then(() => {
        this.loadPayments()
        this.closeCreator()
        this.$store.dispatch(orderActions.getByOrderId, this.orderId)
      })
    },
    closeCreator() {
      this.showCreator = false
      this.newPayment = {
        order: null,
        payment_type_id: null,
        sum: 0,
        purpose: '',
        comment: '',
      }
    },
    loadPayments() {
      this.$store.dispatch(paymentActions.getOrderPayments, this.orderId)
    },
    create() {
      this.showCreator = true

      this.newPayment.order = this.order
      this.newPayment.purpose = 'Оплата по заказу №' + this.order.number
    },

    clear() {
      this.$store.dispatch(paymentActions.clear)
    },
  },
  mounted() {
    this.loadPayments()
  },
  watch: {
    orderId() {
      this.loadPayments()
    },
    order() {
      this.loadPayments()
    }
  },
  beforeDestroy() {
    this.clear()
  }
}
</script>
